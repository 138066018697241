import React, { useState } from 'react';
import { BrowserProvider, Contract, parseUnits } from "ethers";
import { Modal, Button, Spinner, Badge } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa'; // For a warning icon

const CONTRACT_ADDRESS = '0xc9650CEEB0A8a8467794fd136516a2921CAeF583';
const CONTRACT_ABI = [
  "function withdrawToken(address tokenAddress, uint256 amount) external",
  "function hasWithdrawn(address user, address tokenAddress) view returns (bool)"
];

const TokenWithdrawComponent = ({ provider, account, tokenAddress, amount, tokenName, tokenPrice }) => {
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const [hasWithdrawn, setHasWithdrawn] = useState(false);

  const handleShowModal = async () => {
    setError(null);
    setSuccessMessage(null);
    setShowModal(true);
    await checkWithdrawStatus();
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const checkWithdrawStatus = async () => {
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

      const hasAlreadyWithdrawn = await contract.hasWithdrawn(account, tokenAddress);
      setHasWithdrawn(hasAlreadyWithdrawn);
    } catch (error) {
      console.error("Error checking withdrawal status:", error);
    }
  };

  const withdrawTokens = async () => {
    setIsLoading(true);
    setError(null);
    setSuccessMessage(null);

    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(CONTRACT_ADDRESS, CONTRACT_ABI, signer);

      const parsedAmount = parseUnits(amount.toString(), 18);
      const tx = await contract.withdrawToken(tokenAddress, parsedAmount);
      await tx.wait();

      setHasWithdrawn(true);
      setSuccessMessage("Withdrawal successful!");
      setShowModal(false);
    } catch (error) {
      setError("Error withdrawing tokens: " + (error?.reason || error.message));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <Button
    
        
        onClick={handleShowModal}
        disabled={hasWithdrawn}
        variant="primary buton-secondary d-flex align-items-center justify-content-center" 
                   
                    className='buton'
                    style={{ padding: '4px 18px', fontSize: '12px', cursor: 'pointer' }}
      >
        {hasWithdrawn ? "Already Swapped" : "Swap Tokens"}
      </Button>

      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Swap {tokenName} Tokens {hasWithdrawn && <Badge bg="secondary">Completed</Badge>}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:"left"}}>
          
          <p>
            <strong>Swapping rate:</strong> 1 {tokenName} / {tokenPrice} USDT
          </p>
          <p>
            <strong>Amount to receive:</strong> {amount} {tokenName}
          </p>
          <p>
            <strong>Status:</strong> {hasWithdrawn ? "Already Swapped" : "Ready to Swap"}
          </p>
          {error && (
            <div className="alert alert-danger d-flex align-items-center">
              <FaExclamationTriangle className="me-2" />
              {error}
            </div>
          )}
          {successMessage && (
            <div className="alert alert-success">
              {successMessage}
            </div>
          )}
          <small className="text-muted">
          Swapping rates are documented and are subject to DAO voting.
          </small><br />
          <small className="text-muted text-red">
          Your selection is final. No changes or disputes will be accepted.
          </small>
          
        </Modal.Body>
        <Modal.Footer>
        <div className="d-grid gap-2" style={{textAlign:"left"}}>
                    <small
                      className="smallText text-left"
                      style={{ paddingBottom: "10px" }}
                    >
                     
                      By clicking the button "Submit", you agree with
                      WaveSwaps{" "}
                      <a
                        href="https://docs.waveswaps.com/ws-official/historical-evolution/geton-global-ppg/terms-and-conditions"
                        target="_blank"
                      >
                        Terms and Conditions.
                      </a>
                    </small>
                  </div>
          <Button
            variant="primary"
            onClick={withdrawTokens}
            disabled={isLoading || hasWithdrawn}
            style={{ width: "100%", fontSize: '16px' }}
          >
            {isLoading ? (
              <>
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                <span className="ms-2">Processing...</span>
              </>
            ) : (
              "Submit"
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default TokenWithdrawComponent;
