import React, { useState, useEffect } from "react";
import { BrowserProvider, Contract, parseEther, parseUnits, formatEther } from "ethers";
import Chart from "./Chart";
import { BiLineChart, BiLineChartDown, BiInfoCircle } from "react-icons/bi";
import { GoTrophy } from "react-icons/go";
import { IoCloseCircleOutline } from "react-icons/io5";
import { Button, Row, Col, Card, Badge } from "react-bootstrap";
import BettingContractABI from "../../../abis/BettingContractABI.json";
import "./styles.css";
import {FidgetSpinner} from "react-loader-spinner"; // Make sure to install this library with 'npm install react-loader-spinner'
import { FaDollarSign, FaCoins, FaCalendarAlt, FaChartLine, FaGift, FaStackExchange, FaCalculator } from 'react-icons/fa';
import CoinSpin from '../../CoinSpin';
import ReferralButton from '../../ReferralButton';
import { useNavigate } from 'react-router-dom';
import Countdown from "./Countdown";

const usdtAddress = "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"; // Replace with the actual USDC contract address
const waveToken = "0x3c2d4cfF75434205Ec98980f7B506d035CA7d923"; // Replace with the actual USDC contract address
const erc20ABI = [
  "function approve(address spender, uint256 amount) public returns (bool)",
];

const contractAddress = "0xeBcb2Bcce3A9302f5CF2583BF501c850703B9D3c";
//const contractAddress = "0xc152Ff6E1B3654574c778DBD3A1833bfFEd8d4D0";
//const contractAddress = "0x0Bd5d5a90283fCfF153727140e380EA875A846d8";
//const contractAddress = "0x1BD2CC4EC763E43e8e14D2Ef3e43629b52329E2a";
//const contractAddress = "0x7c188ec0C9ceD3AEFDcbDb65af16c9320bAE943d";
//const contractAddress = "0x00E1ccc19433641A19F053DB19CcF29603CB42AB";
const betTime = 12;

function CryptoBet({ provider, account, isConnected }) {
  const [data, setData] = useState([]);
  const [pendingBets, setPendingBets] = useState([]);
  const [currentPrice, setCurrentPrice] = useState(1);
  const [balance, setBalance] = useState(0);
  const [wins, setWins] = useState(0);
  const [losses, setLosses] = useState(0);
  const [packageExpiry, setPackageExpiry] = useState(0);
  const [isBetDisabled, setIsBetDisabled] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(betTime);
  const [lastBet, setLastBet] = useState(null);
  const [lastBetDay, setLastBetDay] = useState(null);
  const [thisDay, setThisDay] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for initial load
  const [isTransactionLoading, setIsTransactionLoading] = useState(false); // Loading state for transactions
  const [lastBetTime, setLastBetTime] = useState(0);
  const [reffCode, setReffCode] = useState('');
  const [reffAddress, setReffAddress] = useState('');
  const [buyAvailable, setBuyAvailable] = useState(false);
  const navigate = useNavigate();
  
  const [packageInfo, setPackageInfo] = useState({
    activePackage: null,
    points: 100,
    dailyBets: 0,
  });

  const packages = [
    { name: "Package 1", price: 100, priceToken: 100, betsPerDay: 10, tokensDay: 0.5 * 10, betsDay:10 },
    { name: "Package 2", price: 200, priceToken: 200, betsPerDay: 25, tokensDay: 0.5 * 25, betsDay:25 },
    { name: "Package 3", price: 500, priceToken: 500, betsPerDay: 70, tokensDay: 0.5 * 70, betsDay:70 },
  ];

  const openFAQs = () => {
    navigate('/FAQs');
  }

  const _setReffCode = async (code) => {
    setReffCode(code);
    if (code.length <= 3) return;
    const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(contractAddress, BettingContractABI, signer);
    
    // Call the buy function on the NFT contract
    // Since we're only working with USDT, we don't need to send ETH value
    const refferalAddr = await contract.inviteCode(code);
    if (refferalAddr.toLowerCase() === "0x0000000000000000000000000000000000000000") {
        setBuyAvailable(false);
    } else {
        if (refferalAddr.toLowerCase() == signer.address.toLowerCase()) {
            alert("You can't reffer yourself!");
            setBuyAvailable(false);
        } else {
            setBuyAvailable(true);
        }

    }
    setReffAddress(refferalAddr);
};

  useEffect(() => {
    const interval = setInterval(() => {
      // Generate a random value between 0.01 and 0.2
      const randomChange = Math.random() * 0.019 + 0.001; // Random value between 0.01 and 0.2

      // Randomly decide whether to increase or decrease the price
      const changeDirection = Math.random() < 0.5 ? -1 : 1; // 50% chance of increasing or decreasing

      const newPrice = currentPrice + randomChange * changeDirection;

      setCurrentPrice(newPrice);
      setData((prevData) => [...prevData.slice(-19), newPrice]);
    }, 1000);

    return () => clearInterval(interval);
  }, [currentPrice]);

  useEffect(() => {
    let timer;
    if (isBetDisabled && cooldownTime > 0) {
      timer = setInterval(() => {
        setCooldownTime((prev) => prev - 1);
      }, 1000);
    } else if (cooldownTime === 0) {
      setIsBetDisabled(false); // Re-enable buttons

      // Check if the bet was correct and update points
      if (lastBet) {
        const wasCorrect =
          (lastBet.isHigh && currentPrice > lastBet.initialPrice) ||
          (!lastBet.isHigh && currentPrice < lastBet.initialPrice);

        setPackageInfo((prev) => ({
          ...prev,
          points: prev.points + (wasCorrect ? 20 : 0),
        }));

        // Update wins or losses based on the bet result
        if (wasCorrect) {
          setWins((prev) => prev + 1);
        } else {
          setLosses((prev) => prev + 1);
        }

        // Clear the last bet after checking the result
        setLastBet(null);
      }
    }

    return () => clearInterval(timer);
  }, [isBetDisabled, cooldownTime, lastBet, currentPrice]);

  useEffect(() => {
    if (provider) {
      fetchBalance();
    }else{
    setLoading(false);
    }
  }, [provider, account]);


  const getCurrentDay = () => {
    return Math.floor(Date.now() / (1000 * 60 * 60 * 24));
};


  const fetchBalance = async () => {
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(contractAddress, BettingContractABI, signer);

      const packageIndex = await contract.getUserPackageIndex(account);
      const userBalance = await contract.points(account);
      setBalance(parseInt(userBalance));
      const activePackage = await contract.packageExpiry(account);
      const points = await contract.points(account);
      const dailyBets = await contract.dailyBets(account);
      const packExpiry = await contract.packageExpiry(account);
      const lastBetDayAcc = await contract.lastBetDay(account);
      
      setPackageExpiry(parseInt(packExpiry.toString()));

      setLastBetDay(parseInt(lastBetDayAcc));
      setThisDay(getCurrentDay);


      setPackageInfo({
        activePackage: parseInt(activePackage),
        points: parseInt(points, 10),
        dailyBets: parseInt(dailyBets, 10),
      });
    } catch (error) {
      console.error("Failed to fetch balance:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleBet = (bet) => {
    if (packageInfo.dailyBets >= 10) {
      alert("Daily limit reached. Please wait until tomorrow.");
      return;
    }
    const currentTime = Date.now();
    if (currentTime - lastBetTime < betTime * 1000) {
      alert("Please wait " + betTime + " seconds between bets.");
      return;
    }
    if (!packageInfo.activePackage) {
      alert("Please buy a package to play the game!");
      return;
    }
    if (packageInfo.points < 10) {
      alert("Not enough points to place a bet.");
      return;
    }

    setPackageInfo((prev) => ({
      ...prev,
      points: prev.points - 10,
      dailyBets: prev.dailyBets + 1,
    }));
    setLastBet({ isHigh: bet === "High", initialPrice: currentPrice });
    setPendingBets((prev) => [
      ...prev,
      { isHigh: bet === "High", price: currentPrice },
    ]);

    setIsBetDisabled(true);
    setLastBetTime(currentTime);
    setCooldownTime(betTime);
  };

  const finalizeBets = async () => {
    if (packageInfo.dailyBets < 10) {
      alert("Complete 10 bets to claim your tokens.");
      return;
    }
    setLoading(true);
    try {
      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();
      const contract = new Contract(contractAddress, BettingContractABI, signer);
      const tx = await contract.claimDailyTokens(packageInfo.points,{
        value:"100000000000000000"
      });
      await tx.wait();

      setPackageInfo((prev) => ({ ...prev, dailyBets: 0 }));
      setPendingBets([]);
    } catch (error) {
      console.error("Failed to claim tokens:", error);
    } finally {
      setLoading(false);
    }
  };



  // Function to handle package purchase with token (either USDC or alternative token)
  const buyPackageToken = async (index, useUSDC) => {
    const selectedPackage = packages[index];
    setLoading(true);
    try {
      const packagePrice = useUSDC
        ? selectedPackage.price * 1e6 // USDC uses 6 decimals
        : parseUnits(selectedPackage.priceToken.toString(), 18); // Alternative token with 18 decimals

      const ethersProvider = new BrowserProvider(provider);
      const signer = await ethersProvider.getSigner();

      // Approve the contract to transfer tokens on behalf of the user
      const tokenContract = new Contract(
        useUSDC ? usdtAddress : waveToken,
        erc20ABI,
        signer
      );

      const approveTx = await tokenContract.approve(
        contractAddress,
        packagePrice
      );
      await approveTx.wait();

      // Call buyPackageWithToken on the betting contract
      const bettingContract = new Contract(
        contractAddress,
        BettingContractABI,
        signer
      );
      const tx = await bettingContract.buyPackageWithToken(index, useUSDC, reffAddress,{
        value:"100000000000000000"
      });
      await tx.wait();

      console.log(`${selectedPackage.name} purchased successfully!`);
      await fetchBalance();
      setLoading(false);
    } catch (error) {
      console.error("Package purchase failed:", error);
      setLoading(false);
    }
  };

  // Display UI
  return (
    <> {loading && (
      <div className="gameLoader">
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div className="gameSpinner">
        <FidgetSpinner  color="#00AEEF" height={140} width={140} />
        </div>
        </div>
    )}
     <div>
      <br />
      <h2 className="pageTitle pt-2">Price Prediction Game</h2>
      <p className="description sub-header">Engaging the Community in Ecosystem Tracking and Rewards.</p> 

      {packageInfo.activePackage && (
        <> {isTransactionLoading ? (
          <center>
         <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <FidgetSpinner color="#00AEEF" height={80} width={80} />
        </center>
        ) : (
<Row className="mt-2 mb-3 g-5 flex-column flex-md-row">
  {/* Chart Column */}
  <Col xs="12"><Countdown timeUntil={packageExpiry} /></Col>
  <Col xs={12} md={6} lg={4} className="mb-3">
    <Card className="p-3 text-center bet-card">
      <Card.Title className="card-title">Price Prediction Chart</Card.Title>
      <Card.Body>
        <Chart data={data} />
      </Card.Body>
    </Card>
  </Col>

  {/* Bet Placement Column */}
  <Col xs={12} md={6} lg={4} className="mb-3">
    <Card className="p-3 text-center bet-card">
      <Card.Title className="card-title">Bet Placement</Card.Title>
      <Card.Body>
        
        <div>
          <Col xs={12}>
            <center style={{ height: "96px", padding:"5px" }}>
              <CoinSpin />
            </center>
          </Col>
        </div>
        <h1 className="price mt-4 pt-2">WAVE = ${currentPrice.toFixed(2)}</h1>
        <div className="bet mt-3">Balance: {packageInfo.points} POINTS</div>
        

        {parseInt(lastBetDay) == parseInt(thisDay) ?(<>
        <hr />
        <div className="bet mt-3">Completed. See you tomorow!</div>
        <hr />
        </>):(<>
        <div className="bet-container my-3">
          {!isBetDisabled ? (
            <h4 className="bet">Place your bet!</h4>
          ) : (
            <h4 className="bet">Next bet in {cooldownTime} seconds</h4>
          )}
        </div>

        <div className="button-container d-flex justify-content-center gap-3">
          <button
            onClick={() => handleBet("High")}
            className="button high-button"
            disabled={isBetDisabled}
          >
            <BiLineChart className="icon-size" /> High
          </button>
          <button
            onClick={() => handleBet("Low")}
            className="button low-button"
            disabled={isBetDisabled}
          >
            <BiLineChartDown className="icon-size" /> Low
          </button>
        </div>
</>)}
        <div className="stats mt-4 d-flex justify-content-center gap-4" style={{ fontSize: '14px' }}>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <GoTrophy className="icon-size" /> Wins: <b>{wins}</b>
          </span>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <IoCloseCircleOutline className="icon-size" /> Losses: <b>{losses}</b>
          </span>
        </div>
      </Card.Body>
    </Card>
  </Col>

  {/* Bets and Finalize Button Column */}
  <Col xs={12} md={6} lg={4} className="mb-3">
    <Card className="p-3 text-center bet-card">
      <Card.Title className="card-title">Pending Bets</Card.Title>
      <Card.Body>
        <div className="bets-info">
          <h4>Bets Left</h4>
          <p>{10 - packageInfo.dailyBets} out of 10</p>
        </div>

        {pendingBets.length > 0 ? (
          <ul className="pending-bets-list mt-3">
            {pendingBets.map((bet, index) => (
              <li key={index} className="d-flex justify-content-between">
                <span className="bet-label">
                  Bet on{" "}
                  <span className="fw-400">
                    {bet.isHigh ? (
                      <>
                        High <BiLineChart className="icon-high" /> 
                      </>
                    ) : (
                      <>
                        Low <BiLineChartDown className="icon-low" /> 
                      </>
                    )}
                  </span>
                </span>

                <span className="bet-price">
                  at <span className="fw-600">${bet.price.toFixed(2)}</span>
                </span>
              </li>
            ))}
          </ul>

        ) : (
          <p className="no-pending-bets mt-3">No pending bets.</p>
        )}

        {packageInfo.dailyBets >= 10 && (
          <button
            onClick={finalizeBets}
            className="button finalize-button mt-4"
          >
            Finalize Bets & Claim Tokens
          </button>
        )}
      </Card.Body>
    </Card>
  </Col>
</Row>

        )}</>
      )}

      {!packageInfo.activePackage && (
        <div className="container">
          <div className="card-title card-title">Choose Your Game Package</div>

<div className="package-grid">
  {packages.map((pkg, index) => (
    <Card
      key={index}
      className={`chat-message package-card ${
        index === 0 ? "basic" : index === 1 ? "advanced" : "premium"
      }`}
    >
      <Card.Header className="bg-geton ">
        <h3 className="package-s">
          {index === 0 ? "Basic" : index === 1 ? "Advanced" : "Premium"}
        </h3>
      </Card.Header>
                
<Card.Body>
  {/* Display both USDT and Token prices */}
  <Card.Text>
    <FaDollarSign className="mr-2 pkg" />
    <span className="pkg-string">Price in USDT:</span> <span className="pkg-price">{pkg.price} USDT</span>
  </Card.Text>
  <Card.Text>
    <FaCalculator className="mr-2 pkg" />
    <span className="pkg-string">Price in WAVE:</span> <span className="pkg-price"> {pkg.priceToken} WAVE</span>
  </Card.Text>
  <Card.Text>
    <FaCalendarAlt className="mr-2 pkg" />
    <span className="pkg-string">Validity:</span> <span className="pkg-price"> 30 days</span>
  </Card.Text>
  <Card.Text>
    <FaChartLine className="mr-2 pkg" />
    <span className="pkg-string">Daily Bet Limit:</span> <span className="pkg-price"> {pkg.betsPerDay} bets/day</span>
  </Card.Text>
  <Card.Text>
    <FaChartLine className="mr-2 pkg" />
    <span className="pkg-string">Monthly Points:</span> <span className="pkg-price"> 100 starting points</span>
  </Card.Text>
  <Card.Text>
    <FaGift className="mr-2 pkg" />
    <span className="pkg-string">Daily Tokens:</span> <span className="pkg-price"> {pkg.tokensDay} WAVE</span>
  </Card.Text>
  <Card.Text>
    <FaCoins className="mr-2 pkg" />
    <span className="pkg-string">Daily Bets:</span> <span className="pkg-price"> {pkg.betsDay} bets</span>
  </Card.Text>


  {buyAvailable ? (
  <>
  <Button
    onClick={() => buyPackageToken(index, true)} // Purchase with USDT
    variant={
      index === 0
        ? "secondary"
        : index === 1
        ? "primary"
        : "success-green"
    }
    className="mr-2"
  >
    <FaDollarSign className="mr-1" /> Buy with USDT
  </Button>
  <Button
    onClick={() => buyPackageToken(index, false)} // Purchase with Token
    variant={
      index === 0
        ? "secondary"
        : index === 1
        ? "primary"
        : "success-green"
    }
  >
    <FaCoins className="mr-1" /> Buy with WAVE
  </Button>
  </>
   ) : (
    <div style={{position:"relative"}}>
        <div className='input-group' style={{ marginBottom: "10px" }}>
            <input
                type="text"
                value={reffCode}
                disabled={!isConnected}
                onChange={(e) => _setReffCode(e.target.value)}
                placeholder="Referral Code"
                className='custom-select'
            />
            
        </div>
        <ReferralButton />
        
    </div>
)}
</Card.Body>
              </Card>
            ))}
          </div>
        </div>
      )}
    </div>
    </>
  );
}

export default CryptoBet;
